/* eslint relay/unused-fields: 0 */
import { useEffect } from 'react';
import { graphql, useFragment } from 'react-relay';
import serverVars from 'server-vars';
import { trackEvent, eventNameConstants, TrackingObject } from 'dibs-tracking';
import { getBuyerId, getAbOptIn } from 'dibs-cookie-jar';
import { getAbUuid } from 'dibs-cookie-jar/exports/abTestHelpers';
import {
    bucketABTest,
    isABTestConfig,
    audienceMatchesDeviceType,
    AB_TEST_TYPES,
} from 'dibs-bucket-ab-tests';
import type { AbTestV2 } from './types';
import {
    clientAbTestV2_useAbTestV2BuyerUser_user$data,
    clientAbTestV2_useAbTestV2BuyerUser_user$key,
} from './__generated__/clientAbTestV2_useAbTestV2BuyerUser_user.graphql';

type AbTestEventOptions = Parameters<typeof trackEvent>[2];

type AbTestType = keyof typeof AB_TEST_TYPES;

/**
 * * overrideUserId --  Pass a user id to use as the seedbase for a BUYER_USER test. If not passed, will
 * use the currently logged in buyer.
 * * testTypes -- Pass an array of test types to bucket. Used mainly to track bucketing decsions.
 * Defaults to [BUYER_CLIENT, BUYER_USER]
 */
type GetAbTestV2Options = {
    overrideUserId?: string;
    testTypes?: AbTestType[];
};

function getSeedBase(
    testType: AbTestV2['config']['testType'],
    options?: GetAbTestV2Options
): string | null {
    if (testType === AB_TEST_TYPES.BUYER_USER) {
        if (options?.overrideUserId) {
            return options.overrideUserId;
        }
        return getBuyerId(document.cookie) || null;
    }
    return getAbUuid(document.cookie);
}

/**
 * This is the core bucketing function for the client side A/B testing.
 * Do not change this logic without considering the implications for the
 * the CDN-based bucketing (in dibs-fastly-js repo).
 *
 * Exported for testing, do not use directly. Use getAbTestV2 instead.
 */
export function __getClientABTestV2(
    config: AbTestV2['config'],
    options?: GetAbTestV2Options
): AbTestV2 | null {
    const seedBase = getSeedBase(config.testType, options);
    if (seedBase && config.isActive) {
        const variant = bucketABTest(config, seedBase);
        if (variant) {
            return { variant, config };
        }
    }
    return null;
}

export function getAbTestV2(testName: string, options?: GetAbTestV2Options): AbTestV2 | null {
    if (typeof window === 'undefined') {
        throw new Error('getAbTestV2 should only be called on the client!');
    }
    // get the { variant?, config } object for the test
    const abTest = serverVars.get('abTestsV2.' + testName) as AbTestV2 | null | undefined;
    const deviceType = serverVars.get('settings.deviceType') as unknown;

    if (!abTest || !isABTestConfig(abTest.config)) {
        // maybe we don't have this test
        return null;
    }
    // These are the test types that we want to bucket on the client
    // We allow the consumer to override this list with the testTypes option
    // because we send test buckets to the BE for BUYER_COOKIE tests
    // even if they weren't bucketed on the CDN because of the routing config
    const testTypesToBucket: string[] = options?.testTypes || [
        AB_TEST_TYPES.BUYER_USER,
        AB_TEST_TYPES.BUYER_CLIENT,
    ];

    // These are the test types that can be bucketed on the server
    const testTypesWithServerDecision: string[] = [
        AB_TEST_TYPES.BUYER_COOKIE,
        AB_TEST_TYPES.SELLER,
    ];

    if (abTest.variant && testTypesWithServerDecision.includes(abTest.config.testType)) {
        // abTest.variant may be set for the following use-cases:
        // - bucket determined in the CDN for BUYER_COOKIE tests
        // - bucket determined in GraphQL via DAL for server-side SELLER tests
        return abTest;
    } else if (
        testTypesToBucket.includes(abTest?.config?.testType || '') &&
        audienceMatchesDeviceType(abTest.config.audience, deviceType)
    ) {
        let abOptInParams = new URLSearchParams();

        try {
            const abOptIn = getAbOptIn(document.cookie) || '';
            abOptInParams = new URLSearchParams(abOptIn);
        } catch {
            // fail silently...
        }

        const abOptInVariant = abOptInParams.get(abTest.config.testName);

        if (abOptInVariant) {
            return {
                ...abTest,
                variant: abOptInVariant,
            };
        }
        // bucket the test on the client
        return __getClientABTestV2(abTest.config, options);
    }
    // Not a test we're currently interested in bucketing
    return null;
}

function trackAbTestEvent({
    variant,
    testName,
    ramp,
    options,
    trackingProperties,
}: {
    variant: string | undefined;
    testName: string | undefined;
    ramp: number | undefined;
    options: AbTestEventOptions;
    trackingProperties?: TrackingObject;
}): void {
    trackEvent(
        {
            category: 'a/b testing v3',
            eventName: eventNameConstants.EVENT_AB_TESTING,
            action: testName,
            interaction_type: testName,
            label: variant,
            step_interaction_name: variant,
            value: ramp,
            ab_uuidCookie: getAbUuid(document.cookie),
            ...trackingProperties,
        },
        null,
        options
    );
}

export function trackAbTestV2Variant(
    testName: string,
    options?: AbTestEventOptions,
    abTestOptions?: GetAbTestV2Options,
    trackingProperties?: TrackingObject
): void {
    const abTest = getAbTestV2(testName, abTestOptions);
    if (abTest) {
        trackAbTestEvent({
            variant: abTest.variant,
            testName,
            ramp: abTest.config.ramp ?? undefined,
            options,
            trackingProperties,
        });
    }
}

type AbTestV2Type =
    | NonNullable<clientAbTestV2_useAbTestV2BuyerUser_user$data['abTestV2List']>[number]
    | null;

const trackedTests = new Set<string>();

export function useAbTestV2BuyerUser({
    testName,
    user: userRef,
    eventOptions,
    shouldFireTracking = true,
}: {
    testName: string;
    user: clientAbTestV2_useAbTestV2BuyerUser_user$key | null | undefined;
    eventOptions?: AbTestEventOptions;
    shouldFireTracking?: boolean;
}): AbTestV2Type {
    const user = useFragment(
        graphql`
            fragment clientAbTestV2_useAbTestV2BuyerUser_user on User {
                abTestV2List(testType: BUYER_USER) {
                    config {
                        createdDate
                        modifiedDate
                        testName
                        description
                        testType
                        audience
                        ramp
                        isActive
                        route
                        variations {
                            value
                            weight
                        }
                    }
                    variant
                }
            }
        `,
        userRef
    );

    const abTestV2 = user?.abTestV2List?.find(test => test?.config?.testName === testName) || null;

    const variant = abTestV2?.variant ?? undefined;
    const ramp = abTestV2?.config?.ramp ?? undefined;
    const immediate = eventOptions?.immediate;

    useEffect(() => {
        // check trackedTests to ensure that tracking is called only once
        // even if hook is mounted multiple times
        if (variant && !trackedTests.has(testName) && shouldFireTracking) {
            trackAbTestEvent({
                variant,
                testName,
                ramp,
                options: immediate !== undefined ? { immediate } : undefined,
            });
            trackedTests.add(testName);
        }
    }, [variant, testName, ramp, immediate, shouldFireTracking]);

    return abTestV2;
}
