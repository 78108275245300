/**
 * @generated SignedSource<<8ce1ca86787e413b586f892e18c59e4c>>
 * @relayHash 1c1d9266df9fd3de7d6c2e877e79756e
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID ferrum/480.0.0-2024-09-16T11:47:45.928Z/initSalesforceChatQuery

import { ConcreteRequest, Query } from 'relay-runtime';
export type initSalesforceChatQuery$variables = {
  isCheckout: boolean;
  isSeller: boolean;
};
export type initSalesforceChatQuery$data = {
  readonly viewer: {
    readonly checkoutLiveChatConfig?: {
      readonly chatConfig: {
        readonly chatBaseLiveAgentContentUrl: string | null;
        readonly chatButtonId: string | null;
        readonly chatDeploymentId: string | null;
        readonly chatLiveAgentUrl: string | null;
        readonly liveAgentDevName: string | null;
        readonly salesForceHost: string | null;
        readonly salesForceOrgId: string | null;
        readonly salesForcePartnersHost: string | null;
      } | null;
    } | null;
    readonly contact1stdibsConfig?: {
      readonly chatConfig: {
        readonly chatBaseLiveAgentContentUrl: string | null;
        readonly chatButtonId: string | null;
        readonly chatDeploymentId: string | null;
        readonly chatLiveAgentUrl: string | null;
        readonly liveAgentDevName: string | null;
        readonly salesForceHost: string | null;
        readonly salesForceOrgId: string | null;
        readonly salesForcePartnersHost: string | null;
      } | null;
    } | null;
  };
};
export type initSalesforceChatQuery = {
  response: initSalesforceChatQuery$data;
  variables: initSalesforceChatQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isCheckout"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isSeller"
},
v2 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Contact1stdibsConfigInitParams",
    "kind": "LinkedField",
    "name": "chatConfig",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "salesForceHost",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "salesForcePartnersHost",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "chatButtonId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "chatDeploymentId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "chatBaseLiveAgentContentUrl",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "chatLiveAgentUrl",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "salesForceOrgId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "liveAgentDevName",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v3 = {
  "condition": "isCheckout",
  "kind": "Condition",
  "passingValue": false,
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "isSeller",
          "variableName": "isSeller"
        }
      ],
      "concreteType": "Contact1stdibsConfig",
      "kind": "LinkedField",
      "name": "contact1stdibsConfig",
      "plural": false,
      "selections": (v2/*: any*/),
      "storageKey": null
    }
  ]
},
v4 = {
  "condition": "isCheckout",
  "kind": "Condition",
  "passingValue": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "CheckoutLiveChatConfig",
      "kind": "LinkedField",
      "name": "checkoutLiveChatConfig",
      "plural": false,
      "selections": (v2/*: any*/),
      "storageKey": null
    }
  ]
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "initSalesforceChatQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "initSalesforceChatQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "ferrum/480.0.0-2024-09-16T11:47:45.928Z/initSalesforceChatQuery",
    "metadata": {},
    "name": "initSalesforceChatQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "d073e540443d2e4be3ac26e34864d0fe";

export default node;
